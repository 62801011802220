import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "electronic-journal"
    }}>{`Electronic Journal`}</h1>
    <p>{`Welcome to the electronic journal`}</p>
    <p>{`The electronic journal is part of the economic logging registry of sales required by law, The Cash Register Systems Regulations, i.e. in norwegian "kassasystemforskriften".  (`}<a parentName="p" {...{
        "href": "https://lovdata.no/dokument/SFE/forskrift/2015-12-18-1616"
      }}>{`https://lovdata.no/dokument/SFE/forskrift/2015-12-18-1616`}</a>{`)`}</p>
    <p>{`The electronic journal receives, stores and serves data for economic transactions and daily sales settlements.`}</p>
    <p>{`For further functional description of the purpose of the electronic journal, please take a look at the "Guides" section.`}</p>
    <p>{`The following are the most common user scenarios of the electronic journal. For a complete documentation of the functionality of the electronic journal, please take a look at the swagger documentation (`}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/electronic-journal/v1/api-docs"
      }}>{`https://petstore.swagger.io/?url=https://api.entur.io/electronic-journal/v1/api-docs`}</a>{`)`}</p>
    <p>{`The API reference(s) can be found here: `}<a parentName="p" {...{
        "href": "https://developer.entur.org/pages-electronic-journal-api"
      }}>{`electronic-journal`}</a></p>
    <h2 {...{
      "id": "simple-functional-scenarios"
    }}>{`Simple functional scenarios`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The endpoints provides functionality as described in the section below. For a complete description, please take a look at the Swagger Documentation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You will need to be authorized either with an internal Entur authorization token or a Partner  authorization token in order to make use of any of the end points.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "complex-functional-scenarios"
    }}>{`Complex functional scenarios`}</h2>
    <p>{`Using electronic journal - Two-step scenarios:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`In order to use the electronic journal at all you will need to create a point of sale first. All use of endpoints for transactions, settlements etc. requiere that there be first a point of sale with a given identifyer. Please refer to the user guide for the POS Register in order to do so.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When using the electronic journal, as mentioned above, all transactions need to be related to a point of sale. Furthermore, every settlement has to be closed at the end of the day. The settlement is meant to be in a time frame of within 24 hours. That is to say, a settlement should be opened in the morning when sales start and closed at the end of the day. Sales the day after are not allowed to be added to the settlement of the previous day.`}</p>
      </li>
    </ul>
    <p>{`Hence use the following scenario:`}</p>
    <h3 {...{
      "id": "post-open-new-settlement"
    }}><inlineCode parentName="h3">{`POST`}</inlineCode>{` `}<a parentName="h3" {...{
        "href": "http://petstore.swagger.io/?url=https://api.dev.entur.io/electronic-journal/v1/api-docs#/settlement-controller/openSettlementUsingPOST"
      }}>{`Open new settlement`}</a></h3>
    <p>{`Open a new settlement: POST to `}<inlineCode parentName="p">{`/settlements`}</inlineCode></p>
    <h3 {...{
      "id": "put-close-settlement"
    }}><inlineCode parentName="h3">{`PUT`}</inlineCode>{` `}<a parentName="h3" {...{
        "href": "http://petstore.swagger.io/?url=https://api.dev.entur.io/electronic-journal/v1/api-docs#/settlement-controller/closeSettlementUsingPUT"
      }}>{`Close settlement`}</a></h3>
    <p>{`Close a settlement with a given id: PUT to /settlements/{id}/close`}</p>
    <p>{`Please refer to the section below for more details on using these two end points.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      